
import axios from 'axios';

const federalUnitService = {
  async fetchFederalUnits() {
    const response = await axios.get('federal_units');
    return response.data;
  },
  async fetchCities(federalUnitId) {
    const response = await axios.get(`federal_units/${federalUnitId}/cities`);
    return response.data;
  },
};

export default federalUnitService;
