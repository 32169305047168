const getAddressByCEP = postalCode => new Promise((resolve, reject) => {
  fetch(`https://viacep.com.br/ws/${postalCode}/json`, { method: 'GET' })
    .then(response => response.json())
    .then((data) => {
      if (data.erro) {
        throw new Error('Dados não encontrados para o CEP informado');
      }

      resolve({
        street: data.logradouro,
        number: data.numero,
        neighbourhood: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
    })
    .catch((err) => {
      reject(err);
    });
});

export default getAddressByCEP;
